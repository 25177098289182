import React, { ReactElement } from "react";
import styled from "styled-components";
import responsiveStyling, { responsiveStylingMax } from "lib/responsive";
import { BackgroundColorPalette } from "lib/styles/colors";

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
  padding-left: 4%;
  padding-right: 4%;
  background-color: ${BackgroundColorPalette.BACKGROUND_LIGHT_GRAY};
  ${responsiveStyling({
    ">1800": `
    padding-left: 7%;
    padding-right: 7%;
    ;
    `,
  })};
  flex-direction: column;
`;

const ContentContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  min-width: 70%;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 24px;
`;

const CardsScrollContainer = styled.div`
  display: flex;
  flex: 0.35;
  min-width: 30%;
  flex-direction: column;
  margin-right: 2%;
  overflow-y: scroll;
`;

const RightSideWrapper = styled.div`
  width: 100%;
  ${responsiveStylingMax({
    "<768": `
    max-width: 752px;
    `,
  })};
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 20px;
  overflow-y: hidden;
  max-height: 100vh;
  margin-bottom: 80px;
`;

interface Props {
  topSection?: ReactElement;
  cards: ReactElement;
  mainContent: ReactElement;
  hasError: boolean;
  errorComponent: ReactElement;
}

/**
 * TODO - turn this scroll wrapper into a helper component
 * (or maybe separate into two helpers)
 * <CardsScrollContainer>
 *  <NetworkCard />  // {children}
 * </CardsScrollContainer
 * @returns
 */
export function SideCardContainerWrapper({
  cards,
  mainContent,
  topSection,
  hasError,
  errorComponent,
}: Props): ReactElement {
  return (
    <Container>
      {Boolean(topSection) && topSection}
      {hasError ? (
        <ErrorContainer>{errorComponent}</ErrorContainer>
      ) : (
        <ContentContainerRow>
          <CardsScrollContainer>{cards}</CardsScrollContainer>
          <RightSideWrapper>{mainContent}</RightSideWrapper>
        </ContentContainerRow>
      )}
    </Container>
  );
}
