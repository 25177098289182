import styled, { keyframes } from "styled-components";

interface BaseStyleProps {
  height?: string;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
}

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonBase = styled.div<BaseStyleProps>`
  display: inline-block;
  height: ${(props) => props.height || "20px"};
  width: ${(props) => props.width || "80%"};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-top: ${(props) => props.marginTop || "0"};
  margin-bottom: ${(props) => props.marginBottom || "8px"};
`;

interface ImageStyleProps {
  size: string;
}
export const SkeletonProfileImage = styled(SkeletonBase)<ImageStyleProps>`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size}px;
  `}
  position: relative;
`;

export const SkeletonButton = styled(SkeletonBase)`
  border-radius: 8px;
  width: ${(props) => props.width || "320px"};
  height: ${(props) => props.height || "64px"};
`;
