import useSWR from "swr";
import { jobsFetcher } from "lib/http/api";
import { JobDetails } from "./types";
import { encodeToCamelCase } from "lib/http/formatForServer";

/**
 * @deprecated - useGetJobByIdV2
 * errors with tokens in this call
 */
export default function useGetJobById(params: {
  jobId: string | null;
  userRefId?: string | null;
  ssrJob: any;
}) {
  const { jobId, userRefId, ssrJob } = params;
  const authEndpoint = `v1/mariners/job/${jobId}?origin=Web`;
  const unAuthEndpoint = `v1/web/maritime/jobs/${jobId}`;
  const { data, error, isValidating } = useSWR(
    !jobId ? null : userRefId ? authEndpoint : unAuthEndpoint,
    jobsFetcher,
    {
      refreshWhenHidden: false,
      revalidateOnFocus: false,
    }
  );

  // @ts-ignore - need to fix axios <Any> type -- low priority
  const job: JobDetails = data?.data ? encodeToCamelCase(data?.data) : {};
  const isLoading = (!data && !error) || isValidating;
  if (error) {
    console.warn("useGetJobById - error: ", error);
  }
  return {
    error,
    isLoading,
    job: ssrJob && !job.refId ? (encodeToCamelCase(ssrJob) as JobDetails) : job,
  };
}
