import Button from "components/Button";
import CenteredLoader from "components/CenteredLoader";
import Text, { TextType } from "components/Text";
import useGetJobById from "core/jobs/useGetJobById";
import { ButtonTypes } from "lib/design-system/buttonTypes";
import { StatusColorPalette } from "lib/styles/colors";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import JobEmploymentInformation from "./JobEmploymentInformation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 24px 0px;
  width: 90%;
`;

interface Props {
  jobRefId: string | null;
  userRefId?: string;
  isMobile?: boolean;
  focusTopCallback: () => void;
}

export default function MobileJobPreviewDetailsView({
  jobRefId,
  userRefId,
  isMobile,
  focusTopCallback,
}: Props): ReactElement {
  const router = useRouter();
  const { isLoading, job, error } = useGetJobById({
    jobId: jobRefId,
    ssrJob: null,
    userRefId,
  });

  useEffect(() => {
    if (job && job.title && !isLoading) {
      focusTopCallback();
    }
  }, [job, isLoading, focusTopCallback]);

  const handleDirectToFullJob = () => {
    router.push(`/jobs/${jobRefId}/view`);
  };

  if (error) {
    return (
      <Container>
        <Text
          type={TextType.ERROR_MESSAGE}
          fontColor={StatusColorPalette.DANGER_TEXT}
        >
          Sorry, There was an error retrieving job information. Please try again
          or contact support.
        </Text>
      </Container>
    );
  }

  if (isLoading || !job) {
    return <CenteredLoader />;
  }

  const { description, requirements, compensation, startDate, employmentType } =
    job;

  return (
    <Container>
      <ButtonContainer>
        <Button
          variant={ButtonTypes.PRIMARY}
          disabled={false}
          isLoading={false}
          onClick={handleDirectToFullJob}
          style={{
            height: "64px",
            marginLeft: "8px",
            marginRight: "8px",
            width: "100%",
          }}
        >
          View Full Job Detail
        </Button>
      </ButtonContainer>
      <JobEmploymentInformation
        isMobile={isMobile ?? false}
        employmentType={employmentType}
        startDate={startDate}
        compensation={compensation}
        requirements={requirements}
        description={description}
      />
    </Container>
  );
}
