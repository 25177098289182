import Button from "components/beta/Button";
import { ReactElement } from "react";
import styled from "styled-components";
import JobsSearchRow from "./JobsSearchRow";
import { ButtonTypes } from "lib/design-system/buttonTypes";
import FeaturedJobsContainer from "./FeaturedJobsContainer";
import { JobDetails } from "core/jobs/types";
import { CurrentlySelectedCard } from "./JobsHome";
import * as gtag from "lib/analytics/gtag";
import {
  AnalyticsEvents,
  InternalAnalyticsEvents,
} from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";
import { useTracking } from "core/tracking/useTracking";
import { responsiveStylingMax } from "lib/responsive";
import ButtonLink from "components/beta/ButtonLink";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  width: 100%;
  ${responsiveStylingMax({
    "<1024": `
    max-width: 100%;
    `,
  })};
`;

const ButtonContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 40px;
`;

interface Props {
  featuredJobs: JobDetails[];
  handleSelectJob: (newSelection: JobDetails) => void;
  handleDeselect: () => void;
  userRefId: string;
  isMobile: boolean;
  currentlySelectedCard: CurrentlySelectedCard;
}
export default function JobsHeader({
  featuredJobs,
  handleSelectJob,
  handleDeselect,
  userRefId,
  isMobile,
  currentlySelectedCard,
}: Props): ReactElement {
  const { internalTrackEvent } = useTracking();

  const handleEmployerClick = () => {
    gtag.event({
      action: "click_employer_looking_button",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.CLICK_EMPLOYER_LOOKING);
    track(AnalyticsEvents.CLICK_EMPLOYER_LOOKING);
  };

  return (
    <Container>
      <JobsSearchRow />
      <ButtonLink href="https://www.product.10ure.com/how-it-works" newTab>
        <ButtonContainer>
          <Button
            variant={ButtonTypes.TEXT_BUTTON}
            isLoading={false}
            disabled={false}
            onClick={handleEmployerClick}
          >
            Employers, looking for qualified candidates?
          </Button>
        </ButtonContainer>
      </ButtonLink>
      <FeaturedJobsContainer
        featuredJobs={featuredJobs ?? []}
        userRefId={userRefId}
        isMobile={isMobile ?? false}
        handleSelectJob={handleSelectJob}
        handleDeselect={handleDeselect}
        currentlySelectedCard={currentlySelectedCard}
      />
    </Container>
  );
}
