import Text, { TextType } from "components/Text";
import { formatLocation } from "lib/formatLocation";
import getDaysAgo from "lib/getDaysAgo";
import responsiveStyling from "lib/responsive";
import {
  PrimaryColorPalette,
  StatusColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import { ReactElement } from "react";
import styled from "styled-components";
import JobPostTimeDisplay from "./JobPostTimeDisplay";
import SkeletonJobDetailsView from "./skeletons/SkeletonJobDetailsView";
import Button, { ButtonTypes } from "components/beta/Button";
import { useRouter } from "next/router";
import JobEmploymentInformation from "./JobEmploymentInformation";
import AppliedIcon from "lib/svgs/AppliedIcon";
import { useGetJobByIdV2 } from "core/jobs/useGetJobByIdv2";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  padding: 16px 40px 40px 40px;
  margin-bottom: 200px;
  border-radius: 8px;
  border-color: ${TextAndShapesPalette.INPUT};
  border-width: 0.5px;
  padding-bottom: 24px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: ${PrimaryColorPalette.MAIN_6};
`;

const InnerScrollContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${responsiveStyling({
    ">1400": `
      flex-direction: row;
      align-items: flex-start;
    `,
  })};
`;

const LeftSideStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const AppliedRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  gap: 8px;
`;

interface RightSideStyleProps {
  isMobile?: boolean;
}
const RightSide = styled.div<RightSideStyleProps>`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${responsiveStyling({
    ">1400": `
    justify-content: center;
    `,
  })};
  margin: 28px 0px;
`;

interface Props {
  jobRefId: string | null;
  userRefId?: string;
}
export default function JobPreviewDetailsView({
  jobRefId,
  userRefId,
}: Props): ReactElement {
  const router = useRouter();
  const { isLoading, job, error } = useGetJobByIdV2({
    jobId: jobRefId,
    ssrJob: null,
    userRefId,
  });

  const handleDirectToFullJob = () => {
    router.push(`/jobs/${jobRefId}/view`);
  };

  if (error) {
    return (
      <Container>
        <Text
          type={TextType.ERROR_MESSAGE}
          fontColor={StatusColorPalette.DANGER_TEXT}
        >
          Sorry, There was an error retrieving job information. Please try again
          or contact support.
        </Text>
      </Container>
    );
  }
  if (isLoading || !job) {
    return <SkeletonJobDetailsView />;
  }
  const {
    title,
    employer,
    description,
    requirements,
    compensation,
    startDate,
    postedDate,
    city,
    state,
    country,
    employmentType,
    userApplied,
  } = job;

  const formattedLocation = formatLocation({
    city,
    country,
    state,
  });

  return (
    <Container>
      <InnerScrollContainer>
        <TopRow>
          <LeftSideStack>
            <Text type={TextType.JOB_TITLE_LABEL}>{title ?? ""}</Text>
            <Text type={TextType.TITLE_1} style={{ marginBottom: "4px" }}>
              {employer ?? ""}
            </Text>
            <Text type={TextType.PARAGRAPH}>{formattedLocation}</Text>
            <JobPostTimeDisplay
              rawAgeInDays={
                postedDate ? String(getDaysAgo(postedDate)) : undefined
              }
            />
            {userApplied && (
              <AppliedRow>
                <AppliedIcon />
                <Text
                  type={TextType.PARAGRAPH}
                  fontColor={StatusColorPalette.SUCCESS_TEXT}
                >
                  Applied
                </Text>
              </AppliedRow>
            )}
          </LeftSideStack>
          <RightSide>
            <Button
              variant={ButtonTypes.PRIMARY}
              disabled={false}
              isLoading={false}
              onClick={handleDirectToFullJob}
              style={{
                height: "64px",
                marginLeft: "8px",
                marginRight: "8px",
                width: "320px",
              }}
            >
              View Full Job Detail
            </Button>
          </RightSide>
        </TopRow>
        <JobEmploymentInformation
          employmentType={employmentType}
          startDate={startDate}
          compensation={compensation}
          requirements={requirements}
          description={description}
        />
      </InnerScrollContainer>
    </Container>
  );
}
