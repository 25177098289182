import React from "react";

const BASE_WIDTH = 19;
const BASE_HEIGHT = 19;

export default function FeaturedStarIcon(props: {
  height?: number;
  width?: number;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${BASE_WIDTH} ${BASE_HEIGHT}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2591 6.71044L11.4275 7.22867H11.9724H17.7799L13.0816 10.6422L12.6407 10.9625L12.8091 11.4807L14.6037 17.004L9.90537 13.5904L9.46453 13.2701L9.02369 13.5904L4.32534 17.004L6.11995 11.4807L6.28834 10.9625L5.8475 10.6422L1.14915 7.22867H6.95663H7.50153L7.66992 6.71044L9.46453 1.1872L11.2591 6.71044Z"
        stroke="#D1941A"
        strokeWidth="1.5"
      />
    </svg>
  );
}
