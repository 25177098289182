import { Props as ButtonProps } from "components/Button";
import NextLink from "next/link";
import React from "react";

interface OwnProps {
  href: string;
  newTab?: boolean;
}

type Props = OwnProps & ButtonProps;

export default function ButtonLink(props: Props): JSX.Element {
  const { href, newTab } = props;
  return (
    <NextLink href={href}>
      <a target={!newTab ? "_self" : "_blank"} rel="noopener noreferrer">
        {props.children}
      </a>
    </NextLink>
  );
}
