import {
  BrandColorPalette,
  PrimaryColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import { ReactElement } from "react";
import styled from "styled-components";
import Text, { TextType } from "components/Text";

interface StyledPageNumProps {
  isCurrent: boolean;
}

const StyledPageNumWrapper = styled.div<StyledPageNumProps>`
  display: flex;
  width: 48px;
  height: 48px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 4px;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  ${({ isCurrent }) => `
    background-color: ${
  isCurrent ? BrandColorPalette.BRANDING : PrimaryColorPalette.MAIN_6
};
    border: 1px solid ${
  isCurrent ? BrandColorPalette.BRANDING : TextAndShapesPalette.INPUT_FIELD
};
  `}
  cursor: pointer;
`;

interface PageMapperProps {
  page: number;
  totalPages: number;
  handleSetPage: (value: number) => void;
}

interface PageNumberProps {
  isCurrent: boolean;
  value: number | string;
  handleSetPage: (value: number) => void;
}

const array = new Array(5).fill(undefined);
/**
 * This def needs a rewrite - but math is hard for finding the center , etc
 */
export default function PageMapper({
  page,
  totalPages,
  handleSetPage,
}: PageMapperProps): ReactElement {
  /**
   * Less than 5 pages
   */
  if (totalPages < 5) {
    const mapArray = new Array(totalPages).fill(undefined);
    return (
      <>
        {mapArray.map((_, index) => {
          return (
            <PageNumber
              key={`page-${index}`}
              isCurrent={index + 1 === page}
              value={index + 1}
              handleSetPage={handleSetPage}
            />
          );
        })}
      </>
    );
  }
  /**
   * First Few Pages
   */
  if ((totalPages > 5 && !page) || page <= 3) {
    return (
      <>
        {array.map((_, index) => {
          return (
            <PageNumber
              key={`page-${index}`}
              isCurrent={index + 1 === page}
              value={index + 1}
              handleSetPage={handleSetPage}
            />
          );
        })}
      </>
    );
    /**
     * Last Couple of pages
     */
  } else if (totalPages > 5 && page > totalPages - 4) {
    const mapArray = [
      totalPages - 4,
      totalPages - 3,
      totalPages - 2,
      totalPages - 1,
      totalPages,
    ];
    return (
      <>
        {mapArray.map((item, index) => {
          return (
            <PageNumber
              key={`page-${index}`}
              isCurrent={item === page}
              value={item}
              handleSetPage={handleSetPage}
            />
          );
        })}
      </>
    );
    /**
     * Middle Section
     */
  } else if (totalPages > 5) {
    return (
      <>
        <PageNumber
          isCurrent={false}
          value={page - 2}
          handleSetPage={handleSetPage}
        />
        <PageNumber
          isCurrent={false}
          value={page - 1}
          handleSetPage={handleSetPage}
        />
        <PageNumber
          isCurrent={true}
          value={page}
          handleSetPage={handleSetPage}
        />
        <PageNumber
          isCurrent={false}
          value={page + 1}
          handleSetPage={handleSetPage}
        />
        <PageNumber
          isCurrent={false}
          value={page + 2}
          handleSetPage={handleSetPage}
        />
      </>
    );
  }
  const lengthArray = new Array(totalPages).fill(undefined);

  return (
    <>
      {lengthArray.map((_, index) => {
        return (
          <PageNumber
            key={`page-num${index}`}
            isCurrent={index + 1 === page}
            value={index + 1}
            handleSetPage={handleSetPage}
          />
        );
      })}
    </>
  );
}

function PageNumber({
  isCurrent,
  value,
  handleSetPage,
}: PageNumberProps): ReactElement {
  const handleClick = () => {
    handleSetPage && handleSetPage(Number(value));
  };
  return (
    <StyledPageNumWrapper isCurrent={isCurrent} onClick={handleClick}>
      <Text
        style={{ textAlign: "center" }}
        type={TextType.TITLE_1}
        fontColor={
          isCurrent ? PrimaryColorPalette.MAIN_6 : TextAndShapesPalette.INPUT
        }
      >
        {String(value)}
      </Text>
    </StyledPageNumWrapper>
  );
}
