import ErrorComponent from "components/beta/ErrorComponent";
import { MobileCardWithClickExpand } from "components/beta/MobileCardWithClickExpand";
import PaginationControlRow from "components/beta/PaginationControlRow";
import { SideCardContainerWrapper } from "components/beta/SideCardContainerWrapper";
import { getOffsetFromPage } from "core/jobs/helpers";
import { JobDetails } from "core/jobs/types";
import { getScreenSettingFromSize } from "lib/checkScreenSize";
import { useAppValues } from "lib/context/AppContext";
import { parseCookies } from "nookies";
import { ReactElement, useEffect, useState } from "react";
import JobCard from "./JobCard";
import JobPreviewDetailsView from "./JobPreviewDetailsView";
import JobsHeader from "./JobsHeader";
import SkeletonJobCard from "./skeletons/SkeletonJobCard";
import { useRouter } from "next/router";
import useGetJobsV2 from "core/jobs/useGetJobsV2";

interface Props {
  xToken?: string | null;
  initialData?: any;
}

export interface CurrentlySelectedCard {
  refId: string | null;
  result: JobDetails | null;
}

export default function JobsHome({ initialData }: Props): ReactElement {
  const { userRefId } = parseCookies(null);
  const router = useRouter();
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);
  const [localRefId] = useState<string | null>(
    userRefId ? String(userRefId) : null
  );
  const { getAppValues, setAppValues } = useAppValues();
  const {
    screenSize,
    jobLocation,
    jobTitle,
    currentPage,
    totalPages,
    limit,
    offset,
  } = getAppValues();
  const { isMobile, isTablet } = getScreenSettingFromSize(screenSize);
  const { jobs, featuredJobs, error, isLoading, totalMatches } = useGetJobsV2({
    // Note - dont put initialData in if there is a search , it messes things up
    initialData: jobLocation || jobTitle ? undefined : initialData,
    jobLocation,
    jobTitle,
    limit: limit,
    offset: offset,
    userRefId: localRefId ? localRefId : null,
  });
  const [currentlySelectedCard, setCurrentlySelectedCard] =
    useState<CurrentlySelectedCard>({ refId: null, result: null });

  /**
   * Sync Pagination values into state
   */
  const currentPages = Math.ceil(totalMatches / limit);
  useEffect(() => {
    if (currentPages !== totalPages) {
      setAppValues({ totalPages: currentPages });
    }
  }, [currentPages, setAppValues]);

  /**
   * Handle switching current user for main view
   */
  useEffect(() => {
    if (jobs && jobs.length > 0 && !isMobile && !isTablet) {
      setHasNoResults(false);
      setCurrentlySelectedCard({
        refId: jobs[0].refId ?? null,
        result: jobs[0],
      });
    } else if (!jobs && !isLoading) {
      setHasNoResults(true);
    }
  }, [jobs?.length, isLoading, isMobile, isTablet]);

  const handleSelectJob = (newSelection: JobDetails) => {
    setCurrentlySelectedCard({
      refId: String(newSelection.refId),
      result: newSelection,
    });
  };

  const handleSelectFeaturedJob = (newSelection: JobDetails) => {
    router.push(`/jobs/${newSelection.refId}/view`);

    setCurrentlySelectedCard({
      refId: String(newSelection.refId),
      result: newSelection,
    });
    setAppValues({ currentPage: 1, offset: 0 });
  };

  const handleDeselect = () => {
    setCurrentlySelectedCard({
      refId: null,
      result: null,
    });
  };

  const handleSetPage = (newPage: number) => {
    const offset = getOffsetFromPage(newPage, limit);
    setAppValues({ currentPage: newPage, offset });
  };

  // const handleSearch = () => {
  //   setAppValues({ currentPage: 1, offset: 0 });
  //   // callNewSearch();
  // };

  // const stillLoading = Boolean(
  //   (!xToken && !userRefId) ||
  //     (isLoading && offset <= 1) ||
  //     (defaultJobs && !initialData && !jobs?.length)
  // );

  if (isMobile || isTablet) {
    return (
      <MobileCardWithClickExpand
        hasError={error ? true : !isLoading && hasNoResults}
        errorComponent={
          <ErrorComponent
            title={
              error
                ? `${error}`
                : "No matching result. Please try another search."
            }
          />
        }
        topSection={
          <JobsHeader
            featuredJobs={featuredJobs}
            userRefId={userRefId}
            isMobile={isMobile || isTablet}
            handleSelectJob={handleSelectFeaturedJob}
            handleDeselect={handleDeselect}
            currentlySelectedCard={currentlySelectedCard}
          />
        }
        cards={
          <JobCardScrollView
            isMobile
            isLoading={isLoading}
            searchResults={jobs}
            handleSelectJob={handleSelectJob}
            handleDeselect={handleDeselect}
            currentlySelectedCard={currentlySelectedCard}
            handleSetPage={handleSetPage}
            page={currentPage}
            totalPages={totalPages}
            userRefId={userRefId}
          />
        }
      />
    );
  }

  return (
    <SideCardContainerWrapper
      hasError={error ? true : !isLoading && hasNoResults}
      errorComponent={
        <ErrorComponent
          title={
            error
              ? `${error}`
              : "No matching result. Please try another search."
          }
        />
      }
      topSection={
        <JobsHeader
          featuredJobs={featuredJobs}
          userRefId={userRefId}
          isMobile={isMobile || isTablet}
          handleSelectJob={handleSelectFeaturedJob}
          handleDeselect={handleDeselect}
          currentlySelectedCard={currentlySelectedCard}
        />
      }
      cards={
        <JobCardScrollView
          isMobile={false}
          isLoading={isLoading}
          searchResults={jobs}
          handleSelectJob={handleSelectJob}
          handleDeselect={handleDeselect}
          currentlySelectedCard={currentlySelectedCard}
          handleSetPage={handleSetPage}
          page={currentPage}
          totalPages={totalPages}
          userRefId={userRefId}
        />
      }
      mainContent={
        <JobPreviewDetailsView
          jobRefId={currentlySelectedCard.refId}
          userRefId={userRefId}
          // result={currentlySelectedCard.result}
        />
      }
    />
  );
}

interface ScrollViewProps {
  isLoading: boolean;
  searchResults: JobDetails[];
  handleSelectJob: (newSelection: JobDetails) => void;
  handleDeselect: () => void;
  currentlySelectedCard: CurrentlySelectedCard;
  page: number;
  totalPages: number;
  userRefId: string;
  isMobile: boolean;
  handleSetPage: (newPage: number) => void;
}

function JobCardScrollView({
  isLoading,
  searchResults,
  handleSelectJob,
  handleDeselect,
  currentlySelectedCard,
  page,
  totalPages,
  handleSetPage,
  userRefId,
  isMobile,
}: ScrollViewProps): ReactElement {
  return (
    <>
      {isLoading && <SkeletonJobCards numberOfCards={5} />}
      {(searchResults ?? []).map((result) => {
        return (
          <JobCard
            isMobile={isMobile}
            isSelected={result.refId === currentlySelectedCard.refId}
            key={result.refId}
            cardDetails={result}
            userRefId={userRefId}
            handleSelectJob={handleSelectJob}
            handleDeselect={handleDeselect}
          />
        );
      })}
      <PaginationControlRow
        page={page}
        totalPages={totalPages}
        handleSetPageExternal={handleSetPage}
      />
    </>
  );
}

function SkeletonJobCards({
  numberOfCards,
}: {
  numberOfCards: number;
}): ReactElement {
  const skeletons = new Array(numberOfCards).fill(" ");
  return (
    <>
      {skeletons.map((_: string, index: number) => {
        return <SkeletonJobCard key={`skeleton-${index}`} />;
      })}
    </>
  );
}
