import { TextAndShapesPalette } from "lib/styles/colors";
import PageLastIcon from "lib/svgs/PageLastIcon";
import PageNextIcon from "lib/svgs/PageNextIcon";
import PagePreviousLastInactiveIcon from "lib/svgs/PagePreviousLastInactiveIcon";
import PagePreviousInactiveIcon from "lib/svgs/PagePreviousInactiveIcon";
import { ReactElement } from "react";
import styled from "styled-components";
import PageMapper from "./PageMapper";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 200px;
`;

interface StyledPaginationButtonProps {
  isDisabled: boolean;
}

const StyledPaginationButton = styled.div<StyledPaginationButtonProps>`
  ${({ isDisabled }) => `
    cursor: ${isDisabled ? "default" : "pointer"};
  `}
`;

interface Props {
  page: number;
  totalPages: number;
  handleSetPageExternal: (value: number) => void;
}

export default function PaginationControlRow({
  page,
  totalPages,
  handleSetPageExternal,
}: Props): ReactElement {
  const isLastPage = page >= totalPages;
  const isFirstPage = page <= 1;

  const handleFirstPage = () => {
    if (isFirstPage) {
      return;
    }
    handleSetPageExternal(1);
  };

  const handlePageBack = () => {
    if (isFirstPage) {
      return;
    }
    handleSetPageExternal(page - 1);
  };

  const handlePageForward = () => {
    if (isLastPage) {
      return;
    }
    handleSetPageExternal(page + 1);
  };

  const handleLastPage = () => {
    if (isLastPage) {
      return;
    }
    handleSetPageExternal(totalPages);
  };

  const handleSetExactPage = (value: number) => {
    if (value !== page) {
      handleSetPageExternal(Number(value));
    }
  };

  if (!totalPages || totalPages === 1) {
    return <Container />;
  }

  return (
    <Container>
      {/* Left Arrows */}
      <StyledPaginationButton
        onClick={handleFirstPage}
        isDisabled={isFirstPage}
      >
        <PagePreviousLastInactiveIcon
          color={
            isFirstPage
              ? TextAndShapesPalette.DISABLED
              : TextAndShapesPalette.BRAND_BLACK
          }
        />
      </StyledPaginationButton>
      <StyledPaginationButton onClick={handlePageBack} isDisabled={isFirstPage}>
        <PagePreviousInactiveIcon
          color={
            isFirstPage
              ? TextAndShapesPalette.DISABLED
              : TextAndShapesPalette.BRAND_BLACK
          }
        />
      </StyledPaginationButton>
      <PageMapper
        page={page}
        totalPages={totalPages}
        handleSetPage={handleSetExactPage}
      />
      {/* Right Arrows */}
      <StyledPaginationButton
        onClick={handlePageForward}
        isDisabled={isLastPage}
      >
        <PageNextIcon
          color={
            isLastPage
              ? TextAndShapesPalette.DISABLED
              : TextAndShapesPalette.BRAND_BLACK
          }
        />
      </StyledPaginationButton>
      <StyledPaginationButton onClick={handleLastPage} isDisabled={isLastPage}>
        <PageLastIcon
          color={
            isLastPage
              ? TextAndShapesPalette.DISABLED
              : TextAndShapesPalette.BRAND_BLACK
          }
        />
      </StyledPaginationButton>
    </Container>
  );
}
