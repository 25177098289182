import Input from "components/Input";
import { ChangeEvent, KeyboardEvent, ReactElement, useState } from "react";
import SearchIcon from "lib/svgs/Search";
import styled from "styled-components";
import { PrimaryColorPalette, TextAndShapesPalette } from "lib/styles/colors";
import { useRouter } from "next/router";
import { useAppValues } from "lib/context/AppContext";
import LocationIcon from "lib/svgs/LocationIcon";
import { setCookie } from "nookies";
import * as gtag from "lib/analytics/gtag";
import { AnalyticsEvents } from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";
import Button, { ButtonType } from "components/Button";
import { TextType as ButtonTextType } from "components/Text_DEPRECATED";
import responsiveStyling, { responsiveStylingMax } from "lib/responsive";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  ${responsiveStyling({
    ">1024": `
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `,
  })};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  width: 400px;
  ${responsiveStylingMax({
    "<480": `
    max-width: 90%;
    `,
  })};
  padding: 21px 18px;
  border: 1px solid ${TextAndShapesPalette.INPUT_FIELD};
  background-color: ${PrimaryColorPalette.MAIN_6};
  border-radius: 8px;
`;

const Spacer = styled.div`
  ${responsiveStylingMax({
    "<1024": `
      margin-top: 16px;
    `,
  })};
  ${responsiveStyling({
    ">1024": `
      margin-left: 16px;
    `,
  })};
`;

interface Props {
  handlePressEnter?: () => void;
}

const MAX_AGE = 10 * 60 * 1000; // 10 min

export default function JobsSearchRow({
  handlePressEnter,
}: Props): ReactElement {
  const router = useRouter();
  const { location: initialLocation, title: initalTitle } = router.query;
  const hasParams = initialLocation || initalTitle;
  const { setAppValues, getAppValues } = useAppValues();
  const { jobTitle, jobLocation } = getAppValues();
  const [localJobTitle, setJobTitle] = useState<string>(jobTitle ?? "");
  const [localJobLocation, setJobLocation] = useState<string>(
    jobLocation ?? ""
  );

  const handleJobTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setJobTitle(event.target.value);
  };
  const handleLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setJobLocation(event.target.value);
  };

  const handleSearch = () => {
    if (hasParams && typeof window !== "undefined") {
      setCookie(null, "hasEditedSearch", "true", {
        maxAge: MAX_AGE,
      });
      router.replace("/jobs", undefined);
    }
    gtag.event({
      action: "jobs_search_clicked",
      category: "engagement",
    });
    track(AnalyticsEvents.JOB_SEARCH_CLICKED);
    setAppValues({
      currentPage: 1,
      jobLocation: localJobLocation,
      jobTitle: localJobTitle,
      offset: 0,
    });
  };

  const handleSearchOnReturn = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!event.key || event.key !== "Enter") {
      return;
    }
    if (handlePressEnter) {
      handlePressEnter();
    } else {
      handleSearch();
    }
  };

  return (
    <Container>
      <SearchContainer>
        <SearchIcon />
        <Input
          h="64px"
          focusBorderColor="white.0"
          borderLeftWidth="0px"
          borderRightWidth="0px"
          borderTopLeftRadius="0px"
          borderTopRightRadius="0px"
          borderBottomLeftRadius="0px"
          borderBottomRightRadius="0px"
          type="jobSearch"
          defaultValue={jobTitle}
          placeholder="Search by job title, skill, or company"
          onChange={handleJobTitleChange}
          onKeyUp={handleSearchOnReturn}
        />
      </SearchContainer>
      <Spacer />
      <SearchContainer>
        <LocationIcon />
        <Input
          h="64px"
          focusBorderColor="white.0"
          borderLeftWidth="0px"
          borderRightWidth="0px"
          borderTopLeftRadius="0px"
          borderTopRightRadius="0px"
          borderBottomLeftRadius="0px"
          borderBottomRightRadius="0px"
          type="jobSearch"
          defaultValue={jobLocation}
          placeholder="City, State, Zip, or Country"
          onChange={handleLocationChange}
          onKeyUp={handleSearchOnReturn}
        />
      </SearchContainer>
      <Spacer />

      <Button
        width="164px"
        textType={ButtonTextType.BUTTON}
        styleType={ButtonType.CANCEL_SECONDARY}
        onClick={handleSearch}
      >
        Search
      </Button>
    </Container>
  );
}
