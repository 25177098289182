import React, { useEffect, useRef } from "react";
import { checkCurrentJwtExp, getXToken } from "lib/http/unAuthSetup";
import nookies, { parseCookies, setCookie } from "nookies";
import { GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";
import { useAppValues } from "lib/context/AppContext";
import { ssrGetCall } from "lib/http/call-old";
import Head from "next/head";
import { track } from "lib/analytics/analytics";
import {
  AnalyticsEvents,
  InternalAnalyticsEvents,
} from "lib/analytics/constants";
import { useTracking } from "core/tracking/useTracking";
import JobsHome from "core/jobs/UI/v2/JobsHome";
import styled from "styled-components";
import { BackgroundColorPalette } from "lib/styles/colors";
import Text, { TextType } from "components/Text";
import { handleTokenExchange } from "core/account/handleTokenExchange";

interface StyleProps {
  backgroundColor?: string;
}

const CenteredContainer = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 100px; */
  ${({ backgroundColor }) => `
  background-color: ${
  backgroundColor
    ? backgroundColor
    : BackgroundColorPalette.BACKGROUND_LIGHT_GRAY
};
`}
`;

const INTERNAL_JOBS_CALL =
  "http://" +
  process.env.MARINER_JOBS_API_HOST +
  ":" +
  process.env.MARINER_JOBS_API_PORT;

// NOTE:  HOST check to account for github build logic, undefined then
const JOBS_API_BASE_URL =
  process.env.ENV === "production" && process.env.MARINER_JOBS_API_HOST
    ? INTERNAL_JOBS_CALL
    : "https://dev-marinerjob-api.10ure.com";

interface InitialData {
  data: any;
  headers: any;
}

async function getInitalJobsToPreload(xToken: string): Promise<InitialData> {
  const res = await ssrGetCall(
    `${JOBS_API_BASE_URL}/api/v1/web/maritime/jobs?origin=Web&limit=20&offset=0`,
    xToken
  );

  const data = await res.json();
  //console.log("length of fetched inital data in getInitalJobsToPreload...", data?.length)

  const id: InitialData = {
    data: data,
    headers: {
      num_results: 20,
      //the below dont really matter
      //just to show bottom pagination controls
      // once a search is ran or user clicks on
      // pagination control these will be parsed
      // from response and updated
      num_total_jobs: 7000,
      num_total_matches: 7000,
    },
  };

  return id;
}

interface GetServerSideProps {
  xToken: string;
  initialLocation: string | null;
  initialTitle: string | null;
  baseUrl?: string;
  hasEditedSearch: boolean;
  initialData: InitialData | null;
  sharedToken: string | null;
  userRefId?: string;
  token?: string;
}

interface JobCookies {
  xToken?: string;
  userToken?: string;
  userRefId?: string;
}

// Fetch initial jobs data server side for SEO
export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const cookies = parseCookies(ctx) as JobCookies;
  const xToken = await getXToken(cookies.xToken);
  const initJobs = await getInitalJobsToPreload(xToken);
  const { location, title, source, code, destination, userrefid, token } =
    ctx.query;
  const baseUrl = ctx.req.url;
  const existingToken = cookies.userToken;
  const existingRefId = cookies.userRefId;

  if (code && Boolean(!existingToken || !existingRefId)) {
    const result = await handleTokenExchange(
      code as string,
      destination ? (destination as string) : "web"
    );
    if (result) {
      nookies.set(ctx, "userToken", result.token, {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      }); // 30 days validity for example
      nookies.set(ctx, "userRefId", result.userRefId, {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
      return {
        redirect: {
          destination: `/jobs?userrefid=${result.userRefId}&token=true`,
        },
      };
    } else {
      return {
        redirect: {
          destination: "/jobs?exchangeerror=true",
        },
      };
    }
  } else if (code && Boolean(existingToken && existingRefId)) {
    return {
      redirect: {
        destination: `/jobs?userrefid=${existingRefId}&token=true`,
      },
    };
  }
  //console.log("running getServerSideProps.....")
  // If source - hit deeplink server for tracking / redirect
  if (source && source === "google") {
    ctx.res.setHeader(
      "Location",
      `https://deeplink.10ure.com/google/jobs-feed`
    );
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  //console.log("server side jobs", initJobs)

  // get inital jobs here
  return {
    props: {
      baseUrl,
      initialData: initJobs,
      initialLocation: location ?? null,
      initialTitle: title ?? null,
      xToken,
      ...(existingToken &&
        existingRefId && {
        token: existingToken,
        userRefId: existingRefId,
      }),
      ...(token &&
        userrefid && {
        token: token ?? null,
        userRefId: userrefid ?? null,
      }),
    },
  };
}

export default function Jobs(props: GetServerSideProps) {
  const router = useRouter();
  const { xToken, initialLocation, initialTitle, initialData } = props;
  const initialLoad = useRef<boolean>(true);
  const { getAppValues, setAppValues } = useAppValues();
  const { jobLocation, jobTitle } = getAppValues();
  const { internalTrackEvent } = useTracking();

  const refreshData = () => {
    router.replace(router.asPath);
  };

  const { xToken: currentX, hasEditedSearch } = parseCookies(null);
  const { validExpiry } = checkCurrentJwtExp(currentX);
  useEffect(() => {
    if (!router.isReady) return;
    if (!validExpiry) {
      refreshData();
    }
  }, [router.isReady, validExpiry]);

  useEffect(() => {
    track(AnalyticsEvents.JOB_FEED_VIEWED);
  }, []);

  useEffect(() => {
    const action = router.query["action"]
      ? router.query["action"]?.toString()
      : InternalAnalyticsEvents.WEBSITE_VISIT;
    internalTrackEvent(action);
  }, []);

  // only preload location on first load
  useEffect(() => {
    const navigationEntries = performance.getEntriesByType("navigation");
    let isFreshLoad = false;

    if (navigationEntries.length > 0) {
      // Cast the entry to PerformanceNavigationTiming
      const navigation = navigationEntries[0] as PerformanceNavigationTiming;
      isFreshLoad = navigation.type === "reload";
    } else {
      // Fallback for older browsers
      isFreshLoad = window.performance.navigation.type === 1;
    }
    if (isFreshLoad) {
      // Clear the hasEditedSearch cookie
      setCookie(null, "hasEditedSearch", "", { maxAge: -1 });
    }
    const hasEdited =
      !isFreshLoad && hasEditedSearch && hasEditedSearch === "true";
    if (hasEdited || jobLocation || jobTitle || !initialLoad.current) {
      return;
    }
    if (initialLoad.current && (initialLocation || initialTitle)) {
      initialLoad.current = false;
      setAppValues({
        jobLocation: initialLocation ?? "",
        jobTitle: initialTitle ?? "",
      });
    }
  }, [
    initialLocation,
    initialTitle,
    setAppValues,
    jobLocation,
    jobTitle,
    initialLoad,
    hasEditedSearch,
  ]);

  useEffect(() => {
    if (xToken && xToken !== currentX) {
      setCookie(null, "xToken", xToken, {});
    }
  }, [xToken, currentX]);
  // if expired, wait for it to generate new token
  return (
    <>
      <Head>
        <title>Maritime Jobs - 10ure</title>
        <meta
          name="description"
          content="Maritime Jobs. Stand out from the sea of applicants by becoming 10ure verified and take your Maritime Career to the next level."
        />
      </Head>
      <CenteredContainer>
        <Text style={{ marginTop: 50 }} type={TextType.HEADING_MEDIUM}>
          Maritime Jobs
        </Text>
      </CenteredContainer>
      <CenteredContainer>
        <Text
          type={TextType.HEADING_SMALL}
          style={{
            marginBottom: "24px",
            marginTop: "10px",
            padding: "0px 16px",
            textAlign: "center",
          }}
        >
          Find your next maritime career
        </Text>
        <h2
          hidden={true}
          style={{
            marginTop: "-30px",
            padding: "0px 16px",
            textAlign: "center",
          }}
        >
          Find your next maritime career. You can find jobs on tug boats, oil
          rigs, passenger vessels. Find a job as a martime professionl. Offshore
          on shorside positions available.
        </h2>
      </CenteredContainer>
      <CenteredContainer>
        <JobsHome
          xToken={validExpiry ? currentX : undefined}
          initialData={initialData}
        />
      </CenteredContainer>
    </>
  );
}
