import { SkeletonBase, SkeletonButton } from "components/beta/SkeletonBase";
import {
  PrimaryColorPalette,
  BrandColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import { ReactElement } from "react";
import styled from "styled-components";

interface ContainerStyleProps {
  isSelected: boolean;
}
const Container = styled.div<ContainerStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px;
  background-color: ${PrimaryColorPalette.MAIN_6};
  border-radius: 8px;
  // position: relative;
  border: 1px solid #f5f5f5;
  margin-bottom: 24px;
  ${({ isSelected }) => `
  border-color: ${
  isSelected ? BrandColorPalette.BRANDING : TextAndShapesPalette.INPUT
};
  background-color: ${isSelected ? "#F8FDFF" : PrimaryColorPalette.MAIN_6};
  `}
  ${({ isSelected }) =>
    isSelected &&
    `
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
`}
`;

const TextVerticalStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;
  align-items: flex-start;
`;

const CountCardsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

interface CardStyleProps {
  isFirst?: boolean;
  isLast?: boolean;
  isCardView: boolean;
}

export const SkeletonJobStatusCards = styled(SkeletonBase)<CardStyleProps>`
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 88px;
  ${({ isFirst }) =>
    !isFirst &&
    `
  margin-left: 8px;
`}
  ${({ isLast }) =>
    !isLast &&
    `
margin-right: 8px;
`}
justify-content: center;
  align-items: center;
`;

export default function SkeletonJobCard(): ReactElement {
  return (
    <Container isSelected={false}>
      <TextVerticalStack>
        <SkeletonBase width="60%" />
        <SkeletonBase width="80%" />
        <SkeletonBase width="30%" />
      </TextVerticalStack>
      <SkeletonButton width="100%" height="40px" marginTop="24px" />
      <CountCardsRow>
        <SkeletonJobStatusCards isFirst isCardView />
        <SkeletonJobStatusCards isCardView />
        <SkeletonJobStatusCards isLast isCardView />
      </CountCardsRow>
    </Container>
  );
}
