import { JobDetails } from "core/jobs/types";
import { ReactElement, useRef } from "react";
import styled from "styled-components";
import {
  BrandColorPalette,
  PrimaryColorPalette,
  StatusColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import JobLogoContainer, { ImageSize } from "./JobLogoContainer";
import useJobOrganizations from "core/jobs/useJobOrganizations";
import Text, { TextType } from "components/Text";
import { formatLocation } from "lib/formatLocation";
import getDaysAgo from "lib/getDaysAgo";
import JobPostTimeDisplay from "./JobPostTimeDisplay";
import MobileJobPreviewDetailsView from "./MobileJobPreviewDetailsView";
import AppliedIcon from "lib/svgs/AppliedIcon";
import { getValidThroughDate } from "core/jobs/JobStructuredData";

interface StyleProps {
  isSelected: boolean;
}

const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  border-width: 0.5px;
  border-radius: 8px;
  margin-top: 4px;
  padding: 16px 16px;
  cursor: pointer;
  ${({ isSelected }) => `
    border-color: ${
  isSelected ? BrandColorPalette.BRANDING : TextAndShapesPalette.INPUT
};
    background-color: ${isSelected ? "#F8FDFF" : PrimaryColorPalette.MAIN_6};
    `}
  ${({ isSelected }) =>
    isSelected &&
    `
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  `}
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const LogoContainer = styled.div`
  width: 94px;
  margin-right: 30px;
`;

const RightSideStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const AppliedRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  gap: 8px;
`;

interface Props {
  userRefId: string;
  cardDetails: JobDetails;
  isSelected: boolean;
  isMobile: boolean;
  handleSelectJob: (newSelection: JobDetails) => void;
  handleDeselect: () => void;
}

export default function JobCard({
  cardDetails,
  isSelected,
  isMobile,
  handleSelectJob,
  handleDeselect,
  userRefId,
}: Props): ReactElement {
  const topCardRef = useRef<HTMLDivElement>(null);
  const { orgInfo } = useJobOrganizations({
    orgRefId: cardDetails.organizationRefId,
    ssrOrgInfo: null,
    userRefId,
  });
  const profilePic = orgInfo?.profilePicUrl ?? cardDetails.employerLogoUrl;
  const employerName = orgInfo?.name ?? cardDetails.employer;

  const handleClick = () => {
    if (isMobile && isSelected) {
      handleDeselect();
      return;
    }
    handleSelectJob(cardDetails);
  };

  const focusTopCallback = () => {
    if (isSelected && isMobile && topCardRef.current) {
      topCardRef.current.scrollIntoView();
    }
  };

  const { city, country, state, postedDate } = cardDetails;
  const formattedLocation = formatLocation({
    city,
    country,
    state,
  });
  const validThrough: string | null = cardDetails.postedDate
    ? getValidThroughDate(cardDetails.postedDate)
    : null;

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    datePosted: cardDetails.postedDate ?? "",
    ...(validThrough && { validThrough }),
    description: cardDetails.description ?? "",
    employmentType: cardDetails.employmentType ?? "",
    hiringOrganization: {
      "@type": "Organization",
      name: cardDetails.employer ?? "",
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: cardDetails.country ?? "",
        addressLocality: cardDetails.city ?? "",
        addressRegion: cardDetails.state ?? "",
      },
    },
    qualifications: cardDetails.requirements ?? "",
    title: cardDetails.title ?? "",
  };

  return (
    <>
      <div ref={topCardRef} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
      <Container onClick={handleClick} isSelected={isSelected}>
        <RowContainer>
          <LogoContainer>
            <JobLogoContainer imageUrl={profilePic} size={ImageSize.SMALL} />
          </LogoContainer>
          <RightSideStack>
            <Text
              type={TextType.JOB_TITLE_LABEL}
              style={{ lineHeight: "28px", marginBottom: "4px" }}
            >
              {cardDetails.title ?? ""}
            </Text>
            <Text type={TextType.PARAGRAPH}>{employerName}</Text>
            <Text type={TextType.PARAGRAPH}>{formattedLocation}</Text>
            <JobPostTimeDisplay
              rawAgeInDays={
                postedDate ? String(getDaysAgo(postedDate)) : undefined
              }
            />
            {cardDetails.userApplied && (
              <AppliedRow>
                <AppliedIcon />
                <Text
                  type={TextType.PARAGRAPH}
                  fontColor={StatusColorPalette.SUCCESS_TEXT}
                >
                  Applied
                </Text>
              </AppliedRow>
            )}
          </RightSideStack>
        </RowContainer>
        {isMobile && isSelected && cardDetails.refId && (
          <MobileJobPreviewDetailsView
            userRefId={userRefId}
            jobRefId={cardDetails.refId}
            isMobile={true}
            focusTopCallback={focusTopCallback}
          />
        )}
      </Container>
    </>
  );
}
