import React from "react";

const BASE_WIDTH = 38;
const BASE_HEIGHT = 38;

export default function PageNextIcon(props: {
  height?: number;
  width?: number;
  color?: string;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7372 24.7002C16.3401 25.0974 16.3401 25.7412 16.7372 26.1384C17.1343 26.5355 17.7782 26.5355 18.1753 26.1384L24.7929 19.5208C25.1834 19.1303 25.1834 18.4971 24.7929 18.1066L18.1753 11.489C17.7782 11.0919 17.1343 11.0919 16.7372 11.489C16.3401 11.8861 16.3401 12.53 16.7372 12.9271L22.6238 18.8137L16.7372 24.7002Z"
        fill={props.color ?? "#4D4D4D"}
      />
    </svg>
  );
}
