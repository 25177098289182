import { ScreenSizes } from "core/home/types";

export function getScreenSettingFromSize(screenSize?: ScreenSizes): {
  isMobile: boolean;
  isTablet: boolean;
  isComputer: boolean;
} {
  if (!screenSize) {
    return {
      isComputer: true,
      isMobile: false,
      isTablet: false,
    };
  }
  if (screenSize === ScreenSizes.XS || screenSize === ScreenSizes.SM) {
    return {
      isComputer: false,
      isMobile: true,
      isTablet: false,
    };
  }
  if (screenSize === ScreenSizes.MD) {
    return {
      isComputer: false,
      isMobile: false,
      isTablet: true,
    };
  }
  return {
    isComputer: true,
    isMobile: false,
    isTablet: false,
  };
}
