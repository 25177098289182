import React from "react";

const BASE_WIDTH = 71;
const BASE_HEIGHT = 71;

export default function DefaultCompanyProfileIcon(props: {
  height?: number | string;
  width?: number | string;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${BASE_WIDTH} ${BASE_HEIGHT}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7852 67.7333H42.9481M60.6519 67.7333V11.0815C60.6519 9.20336 59.9058 7.40215 58.5777 6.07412C57.2497 4.74608 55.4485 4 53.5704 4H18.163C16.2848 4 14.4836 4.74608 13.1556 6.07412C11.8276 7.40215 11.0815 9.20336 11.0815 11.0815V67.7333H60.6519ZM60.6519 67.7333H67.7333H60.6519ZM60.6519 67.7333H42.9481H60.6519ZM11.0815 67.7333H4H11.0815ZM11.0815 67.7333H28.7852H11.0815ZM25.2444 18.163H28.7852H25.2444ZM25.2444 32.3259H28.7852H25.2444ZM42.9481 18.163H46.4889H42.9481ZM42.9481 32.3259H46.4889H42.9481ZM28.7852 67.7333V50.0296C28.7852 49.0906 29.1582 48.19 29.8222 47.5259C30.4863 46.8619 31.3869 46.4889 32.3259 46.4889H39.4074C40.3465 46.4889 41.2471 46.8619 41.9111 47.5259C42.5751 48.19 42.9481 49.0906 42.9481 50.0296V67.7333H28.7852Z"
        stroke="#B4B4BB"
        strokeWidth="6.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
