import Text, { TextType } from "components/Text";
import { TextAndShapesPalette } from "lib/styles/colors";
import TimerIcon from "lib/svgs/Timer";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  rawAgeInDays?: string;
  mobile?: boolean;
}

function getDaysText(rawDays?: string): string {
  if (rawDays && Number(rawDays) > 30) {
    return "30+ Days";
  }
  return rawDays && Number(rawDays) > 0 ? `${rawDays} Days Old` : "Today";
}

export default function JobPostTimeDisplay(props: Props): JSX.Element {
  const { rawAgeInDays } = props;
  const formattedText = getDaysText(rawAgeInDays);
  return (
    <Container>
      <TimerIcon />
      <Text
        style={{ marginLeft: "4px", marginTop: props.mobile ? "1px" : "2px" }}
        type={TextType.PARAGRAPH}
        fontColor={TextAndShapesPalette.INPUT}
      >
        {formattedText}
      </Text>
    </Container>
  );
}
