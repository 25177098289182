import { useCallback, useEffect, useState, useRef } from "react";
import { JobDetails } from "./types";
import { call } from "lib/http/call";
import { MethodType, ResponseStatus } from "lib/http/types";
import { JobQueryParams, setupQueryParams } from "./service";
import { ServiceEndpoints } from "lib/http/constants";

const LIMIT = 25;

export interface GetJobParams {
  userRefId: string | null;
  jobTitle: string;
  jobLocation: string;
  applied?: boolean;
  initialData?: any | null;
  offset?: number;
  limit?: number;
  xTokenFromServer?: string | null;
}

export interface JobCounts {
  numTotalJobs?: number;
  numResults?: number;
  numTotalMatches?: number;
}

export interface SeparatedJobsOutput {
  jobs: JobDetails[];
  featuredJobs: JobDetails[];
}

export default function useGetJobsV2(params: GetJobParams) {
  const { offset, limit, jobTitle, jobLocation, applied, initialData } = params;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jobsData, setJobsData] = useState<SeparatedJobsOutput>(() => {
    const { jobs, featuredJobs } = formatJobsData(initialData?.data ?? []);
    return {
      featuredJobs,
      jobs,
    };
  });
  const [error, setError] = useState<string | null>(null);
  const jobCounts = useRef<JobCounts>({
    numResults: initialData?.headers?.num_results ?? 0,
    numTotalJobs: initialData?.headers?.num_total_jobs ?? 0,
    numTotalMatches: initialData?.headers?.num_total_matches ?? 0,
  });

  /**
   * Note old jobs had some "unpubbed jobs" logic we might need to transfer
   */

  const fetchJobs = useCallback(
    async (params: JobQueryParams) => {
      setIsLoading(true);
      const { jobLocation, jobTitle, limit, offset } = params;
      const queryParams = setupQueryParams({
        jobLocation,
        jobTitle,
        limit: limit ?? LIMIT,
        offset,
      });

      const authEndpoint = applied
        ? `v1/mariners/jobs?applicant_status=applied${queryParams}`
        : `v1/mariners/jobs${queryParams}`;
      const unAuthEndpoint = `v1/web/maritime/jobs${queryParams}`;
      const hasToken = document.cookie.includes("userToken");
      const endpoint = hasToken ? authEndpoint : unAuthEndpoint;

      const result = await call<SeparatedJobsOutput>({
        endpoint: `${ServiceEndpoints.JOBS}/${endpoint}`,
        method: MethodType.GET,
      });
      if (result.headers) {
        const numResults = result.headers.get("num_results");
        const numTotalJobs = result.headers.get("num_total_jobs");
        const numTotalMatches = result.headers.get("num_total_matches");
        jobCounts.current = {
          numResults: numResults ? Number(numResults) : 0,
          numTotalJobs: numTotalJobs ? Number(numTotalJobs) : 0,
          numTotalMatches: numTotalMatches ? Number(numTotalMatches) : 0,
        };
      }
      if (result.status === ResponseStatus.SUCCESS) {
        // console.log("v2  in success: ", result.body);
        // setJobsData(result.body);

        const { jobs, featuredJobs } = formatJobsData(result?.body ?? []);
        setJobsData({ featuredJobs, jobs });
      } else {
        // console.log("v2  in error: ", result.error);
        setError("Failed to fetch jobs. Please try again.");
      }
      setIsLoading(false);
    },
    [applied]
  );

  useEffect(() => {
    fetchJobs({
      jobLocation,
      jobTitle,
      limit,
      offset,
    });
  }, [limit, offset, jobTitle, jobLocation, applied]);

  const noResultsForQuery = Boolean(
    (jobTitle || jobLocation) &&
      !isLoading &&
      !error &&
      (!jobsData.jobs || jobsData.jobs.length === 0)
  );

  return {
    error: noResultsForQuery
      ? "No results for that search. Please try something different."
      : error,
    featuredJobs: jobsData.featuredJobs,
    isLoading,
    jobCounts: jobCounts.current,
    jobs: jobsData.jobs,
    totalHits: jobCounts.current.numTotalJobs ?? 0,
    totalMatches: jobCounts.current.numTotalMatches ?? 0,
  };
}

// Note - snake case to handle "initialData"
function formatJobsData(data: any): SeparatedJobsOutput {
  if (!data || data.length === 0) {
    return {
      featuredJobs: [],
      jobs: [],
    };
  }
  const output: SeparatedJobsOutput = {
    featuredJobs: [],
    jobs: [],
  };
  data.forEach((currJob: any) => {
    const formattedJob: JobDetails = {
      city: currJob.city ?? null,
      country: currJob.country,
      createdAt: currJob.createdAt,
      description: currJob.description,
      employer: currJob.employer,
      employerLogoUrl: currJob.employerLogoUrl ?? currJob.employer_logo_url,
      employmentType: currJob.employmentType ?? currJob.employment_type,
      isFeatured: currJob.isFeatured ?? currJob.is_featured,
      isResumeRequired: currJob.isResumeRequired ?? currJob.is_resume_required,
      organizationJobRefId:
        currJob.organizationJobRefId ?? currJob.organization_job_ref_id,
      organizationRefId:
        currJob.organizationRefId ?? currJob.organization_ref_id,
      postedDate: currJob.postedDate ?? currJob.posted_date,
      refId: currJob.refId,
      requirements: currJob.requirements,
      state: currJob.state,
      title: currJob.title,
      userApplied: currJob.userApplied ?? currJob.user_applied ?? false,
    };
    if (formattedJob.isFeatured) {
      output.featuredJobs.push(formattedJob);
    } else {
      output.jobs.push(formattedJob);
    }
  });
  return output;
}
