import React from "react";

const BASE_WIDTH = 38;
const BASE_HEIGHT = 38;

export default function PagePreviousLastInactiveIcon(props: {
  height?: number;
  width?: number;
  color?: string;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2628 24.7002C23.6599 25.0974 23.6599 25.7412 23.2628 26.1384C22.8657 26.5355 22.2218 26.5355 21.8247 26.1384L15.2071 19.5208C14.8166 19.1303 14.8166 18.4971 15.2071 18.1066L21.8247 11.489C22.2218 11.0919 22.8657 11.0919 23.2628 11.489C23.6599 11.8861 23.6599 12.53 23.2628 12.9271L17.3762 18.8137L23.2628 24.7002Z"
        fill={props.color ?? "#D9D9D9"}
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="15.25"
        y2="-0.75"
        transform="matrix(0 1 1 0 14 11)"
        stroke={props.color ?? "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
