import responsiveStyling from "lib/responsive";
import { BackgroundColorPalette } from "lib/styles/colors";
import { ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${BackgroundColorPalette.BACKGROUND_LIGHT_GRAY};
  padding: 0px 16px;
  ${responsiveStyling({
    ">480": `
    padding: 0px 40px;
    `,
  })};
`;

const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 24px;
`;

const CardsScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  min-width: 90%;
  width: 100%;
`;

interface Props {
  topSection?: ReactElement;
  cards: ReactElement;
  hasError: boolean;
  errorComponent: ReactElement;
}
export function MobileCardWithClickExpand({
  cards,
  topSection,
  hasError,
  errorComponent,
}: Props): ReactElement {
  return (
    <Container>
      {Boolean(topSection) && topSection}
      {hasError ? (
        <ErrorContainer>{errorComponent}</ErrorContainer>
      ) : (
        <CardsScrollContainer>{cards}</CardsScrollContainer>
      )}
    </Container>
  );
}
