import DefaultCompanyProfileIcon from "lib/svgs/DefaultCompanyProfileIcon";
import Image from "next/image";
import { ReactElement } from "react";
import styled from "styled-components";

interface ImageSizeProps {
  size: string;
}
const ImageContainer = styled.div<ImageSizeProps>`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
  border-radius: 80px;
  position: relative;
`;

const InnerImageContainer = styled.div<ImageSizeProps>`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
  margin-right: 12px;
  border-radius: 80px;
  // Looks better when overflow not hidden
  // overflow: hidden;
  box-sizing: border-box;
`;

export enum ImageSize {
  XS_SMALL = "xs_small",
  SMALL = "small",
  NORMAL = "normal",
  LARGE = "large",
}

interface Props {
  imageUrl?: string | null;
  size?: ImageSize;
}

export function getViewSizeFromEnum(size?: ImageSize): {
  containerSize: string;
  imageSize: string;
} {
  switch (size) {
    case ImageSize.XS_SMALL:
      return {
        containerSize: "72",
        imageSize: "72",
      };
    case ImageSize.SMALL:
      return {
        containerSize: "90",
        imageSize: "90",
      };
    case ImageSize.NORMAL:
    default:
      return {
        containerSize: "100",
        imageSize: "100",
      };
    case ImageSize.LARGE:
      return {
        containerSize: "160",
        imageSize: "160",
      };
  }
}

export default function JobLogoContainer({
  imageUrl,
  size,
}: Props): ReactElement {
  const { containerSize, imageSize } = getViewSizeFromEnum(size);
  if (!imageUrl) {
    return (
      <ImageContainer size={containerSize}>
        <InnerImageContainer size={containerSize}>
          <DefaultCompanyProfileIcon
            width={`${imageSize}px`}
            height={`${imageSize}px`}
          />
        </InnerImageContainer>
      </ImageContainer>
    );
  }
  return (
    <ImageContainer size={containerSize}>
      <InnerImageContainer
        size={containerSize}
        // style={{
        //   borderColor: `${TextAndShapesPalette.PLACEHOLDER}`,
        //   borderWidth: "1.2px",
        // }}
      >
        <Image
          objectFit="contain"
          width="100px"
          height="100px"
          src={String(imageUrl)}
          alt="Picture of the Employer Logo"
          unoptimized={true}
        />
        {/* <Image
          // style={{
          //   borderColor: `${TextAndShapesPalette.PLACEHOLDER}`,
          //   borderWidth: "3px",
          // }}
          src={imageUrl}
          width={`${imageSize}px`}
          height={`${imageSize}px`}
        /> */}
      </InnerImageContainer>
    </ImageContainer>
  );
}
