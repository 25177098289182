import React from "react";

const BASE_WIDTH = 38;
const BASE_HEIGHT = 38;

export default function PagePreviousInactiveIcon(props: {
  height?: number;
  width?: number;
  color?: string;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2628 13.2998C23.6599 12.9026 23.6599 12.2588 23.2628 11.8616C22.8657 11.4645 22.2218 11.4645 21.8247 11.8616L15.2071 18.4792C14.8166 18.8697 14.8166 19.5029 15.2071 19.8934L21.8247 26.511C22.2218 26.9081 22.8657 26.9081 23.2628 26.511C23.6599 26.1139 23.6599 25.47 23.2628 25.0729L17.3763 19.1863L23.2628 13.2998Z"
        fill={props.color ?? "#D9D9D9"}
      />
    </svg>
  );
}
