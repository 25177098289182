import { ReactElement } from "react";
import styled from "styled-components";
import { SkeletonBase, SkeletonButton } from "components/beta/SkeletonBase";
import {
  BackgroundColorPalette,
  TextAndShapesPalette,
  PrimaryColorPalette,
} from "lib/styles/colors";
import { SkeletonJobStatusCards } from "./SkeletonJobCard";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  margin-bottom: 200px;
  border-radius: 8px;
  padding-bottom: 24px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  overflow-y: auto;
  top: 20px;
  border-color: ${TextAndShapesPalette.INPUT};
  border-width: 0.5px;
  background-color: ${PrimaryColorPalette.MAIN_6};
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const LeftSideStack = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 12px;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const JobDetailsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 32px;
`;

const JobDetailsFullWidthSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;
const JobDetailsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
`;

const BorderSpacer = styled.div`
  width: 100%;
  height: 4px;
  margin-bottom: 40px;
  background-color: ${BackgroundColorPalette.BASIC_DECORATION_GRAY};
`;

interface SpacerProps {
  spacing?: string;
}
const Spacer = styled.div<SpacerProps>`
  width: 100%;
  height: 4px;
  margin-bottom: 24px;
`;

export const SkeletonApplicantRow = styled(SkeletonBase)`
  width: 100%;
  height: 120px;
  padding-top: 24px;
`;

export default function SkeletonJobDetailsView(): ReactElement {
  return (
    <Container>
      <TopRow>
        <LeftSide>
          <LeftSideStack>
            <SkeletonBase width="80%" height="32px" />
            <SkeletonBase width="100%" />
          </LeftSideStack>
          <SkeletonButton width="84px" />
        </LeftSide>
        <RightSide>
          <SkeletonJobStatusCards isFirst isCardView />
          <SkeletonJobStatusCards isCardView />
          <SkeletonJobStatusCards isLast isCardView />
        </RightSide>
      </TopRow>
      {/* Active / date / posted by */}
      <Spacer />
      <SkeletonBase width="30%" />
      <SkeletonBase width="30%" />
      <SkeletonBase width="40%" />
      <Spacer />
      <BorderSpacer />
      {/* Description Section*/}
      {/* Description */}
      <JobDetailsFullWidthSectionContainer>
        <SkeletonBase width="30%" />
        <SkeletonBase width="80%" height="80px" marginTop="12px" />
      </JobDetailsFullWidthSectionContainer>
      {/* Qualifications */}
      <JobDetailsFullWidthSectionContainer>
        <SkeletonBase width="30%" />
        <SkeletonBase width="80%" height="80px" marginTop="12px" />
      </JobDetailsFullWidthSectionContainer>
      <JobDetailsRow>
        {/* Employment Type */}
        <JobDetailsSectionContainer>
          <SkeletonBase width="30%" />
          <SkeletonBase width="80%" marginTop="12px" />
        </JobDetailsSectionContainer>
        {/* Start Date */}
        <JobDetailsSectionContainer>
          <SkeletonBase width="30%" />
          <SkeletonBase width="80%" marginTop="12px" />
        </JobDetailsSectionContainer>
        {/* Compensation */}
        <JobDetailsSectionContainer>
          <SkeletonBase width="100%" />
          <SkeletonBase width="100%" marginTop="12px" />
        </JobDetailsSectionContainer>
      </JobDetailsRow>
      {/* Applicants */}
      <Spacer />
      <BorderSpacer />
      <SkeletonApplicantRow />
      <SkeletonApplicantRow />
      <SkeletonApplicantRow />
    </Container>
  );
}
