import { JobDetails } from "core/jobs/types";
import { ReactElement, useRef } from "react";
import styled from "styled-components";
import {
  BrandColorPalette,
  PrimaryColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import JobLogoContainer, { ImageSize } from "./JobLogoContainer";
import useJobOrganizations from "core/jobs/useJobOrganizations";
import Text, { TextType } from "components/Text";
import { formatLocation } from "lib/formatLocation";
import MobileJobPreviewDetailsView from "./MobileJobPreviewDetailsView";
import { getValidThroughDate } from "core/jobs/JobStructuredData";

interface StyleProps {
  isSelected: boolean;
}
const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
  border-width: 0.5px;
  border-radius: 8px;
  padding: 4px 16px;
  cursor: pointer;
  ${({ isSelected }) => `
    border-color: ${
  isSelected ? BrandColorPalette.BRANDING : TextAndShapesPalette.INPUT
};
    background-color: ${isSelected ? "#F8FDFF" : PrimaryColorPalette.MAIN_6};
    `}
  ${({ isSelected }) =>
    isSelected &&
    `
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  `}
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface Props {
  userRefId: string;
  cardDetails: JobDetails;
  isSelected: boolean;
  isMobile: boolean;
  handleSelectJob: (newSelection: JobDetails) => void;
  handleDeselect: () => void;
}

export default function FeaturedJobVerticalCard({
  cardDetails,
  isSelected,
  handleSelectJob,
  handleDeselect,
  userRefId,
  isMobile,
}: Props): ReactElement {
  const topCardRef = useRef<HTMLDivElement>(null);
  const { orgInfo } = useJobOrganizations({
    orgRefId: cardDetails.organizationRefId,
    ssrOrgInfo: null,
    userRefId,
  });

  const profilePic = orgInfo?.profilePicUrl ?? cardDetails.employerLogoUrl;
  const employerName = orgInfo?.name ?? cardDetails.employer;

  const handleClick = () => {
    if (isMobile && isSelected) {
      handleDeselect();
      return;
    }
    handleSelectJob(cardDetails);
  };

  const focusTopCallback = () => {
    if (isSelected && isMobile && topCardRef.current) {
      topCardRef.current.focus({
        preventScroll: true,
      });
    }
  };

  const { city, country, state } = cardDetails;
  const formattedLocation = formatLocation({
    city,
    country,
    state,
  });
  const validThrough: string | null = cardDetails.postedDate
    ? getValidThroughDate(cardDetails.postedDate)
    : null;

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    datePosted: cardDetails.postedDate ?? "",
    ...(validThrough && { validThrough }),
    description: cardDetails.description ?? "",
    employmentType: cardDetails.employmentType ?? "",
    hiringOrganization: {
      "@type": "Organization",
      name: cardDetails.employer ?? "",
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: cardDetails.country ?? "",
        addressLocality: cardDetails.city ?? "",
        addressRegion: cardDetails.state ?? "",
      },
    },
    qualifications: cardDetails.requirements ?? "",
    title: cardDetails.title ?? "",
  };

  return (
    <>
      <div ref={topCardRef} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
      <Container onClick={handleClick} isSelected={isSelected}>
        <LogoContainer>
          <JobLogoContainer imageUrl={profilePic} size={ImageSize.SMALL} />
        </LogoContainer>
        <Text style={{ textAlign: "center" }} type={TextType.JOB_TITLE_LABEL}>
          {cardDetails.title ?? ""}
        </Text>
        <Text style={{ textAlign: "center" }} type={TextType.PARAGRAPH}>
          {employerName}
        </Text>
        <Text style={{ textAlign: "center" }} type={TextType.PARAGRAPH}>
          {formattedLocation}
        </Text>
        {/* <JobPostTimeDisplay
            rawAgeInDays={
              postedDate ? String(getDaysAgo(postedDate)) : undefined
            }
          /> */}
        {isMobile && isSelected && cardDetails.refId && (
          <MobileJobPreviewDetailsView
            userRefId={userRefId}
            jobRefId={cardDetails.refId}
            isMobile={true}
            focusTopCallback={focusTopCallback}
          />
        )}
      </Container>
    </>
  );
}
