import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { JobDetails } from "core/jobs/types";
import { StatusColorPalette } from "lib/styles/colors";
import Text, { TextType } from "components/Text";
import FeaturedStarIcon from "lib/svgs/FeaturedStarIcon";
import FeaturedJobVerticalCard from "./FeaturedJobVerticalCard";
import { CurrentlySelectedCard } from "./JobsHome";
import responsiveStyling, { responsiveStylingMax } from "lib/responsive";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${StatusColorPalette.WARNING_FILL};
  border: 1px solid ${StatusColorPalette.WARNING_TEXT};
  border-radius: 8px;
  padding: 16px 16px;
  ${responsiveStylingMax({
    "<768": `
    width: 100%;
    min-width: 100%;
    `,
  })}
  ${responsiveStyling({
    ">768": `
    width: 100%;
    max-width: 100%;
    `,
  })};
  margin-bottom: 40px;
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  background-color: ${StatusColorPalette.WARNING_BACKGROUND};
  border-radius: 8px;
  padding: 10px 16px;
`;

const JobCardOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${responsiveStyling({
    ">768": `
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    `,
    ">1024": `
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    `,
  })};
  align-items: center;
  width: 100%;
  overflow: scroll;
`;

interface CardContainerStyleProps {
  parentDivWidth: number | null;
  maxWidthPercent: number;
}

const JobCardContainer = styled.div<CardContainerStyleProps>`
  width: 100%;
  height: 100%;
  margin: 0px 8px;
  margin-bottom: 24px;
  // Make min-width about 1/2 (minus margin) for small screen
  ${({ parentDivWidth }) =>
    parentDivWidth !== null &&
    `
    ${responsiveStylingMax({
    "<1024": `
      min-width: ${(parentDivWidth / 2 - 12).toFixed()}px;
      `,
  })};
  `}
  ${({ maxWidthPercent }) => `
  ${responsiveStyling({
    ">768": `
    margin-bottom: 0px;
    min-height: 100%;
    `,
    ">1024": `
    max-width: ${maxWidthPercent}%;
    `,
  })};
  `}
`;

interface Props {
  featuredJobs: JobDetails[];
  userRefId: string;
  currentlySelectedCard: CurrentlySelectedCard;
  isMobile?: boolean;
  handleSelectJob: (newSelection: JobDetails) => void;
  handleDeselect: () => void;
}

function getMaxWidthPercent(featuredJobs: JobDetails[]): number {
  if (!featuredJobs || featuredJobs.length === 0) {
    return 0;
  }
  if (featuredJobs.length === 1) {
    return 90;
  }
  if (featuredJobs.length === 2) {
    return 50;
  }
  // greater than 2
  return 30;
}

export default function FeaturedJobsContainer({
  featuredJobs,
  userRefId,
  currentlySelectedCard,
  isMobile,
  handleSelectJob,
  handleDeselect,
}: Props): ReactElement {
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentDivWidth, setParentDivWidth] = useState<number | null>(null);
  const maxWidthPercent = getMaxWidthPercent(featuredJobs);

  useEffect(() => {
    if (featuredJobs.length === 0) {
      return;
    }
    const width = parentRef.current?.offsetWidth;
    if (width) {
      setParentDivWidth(width);
    }
  }, [setParentDivWidth, featuredJobs?.length]);

  if (!featuredJobs || featuredJobs.length === 0) {
    return <div />;
  }

  return (
    <Container>
      <IconRow>
        <FeaturedStarIcon />
        <Text
          type={TextType.TITLE_1}
          fontColor={StatusColorPalette.WARNING_TEXT}
          style={{ marginLeft: "8px" }}
        >
          Featured Jobs
        </Text>
      </IconRow>
      <JobCardOuterContainer ref={parentRef}>
        {featuredJobs.map((job, index) => {
          if (index > 2) {
            return null;
          }
          return (
            <JobCardContainer
              maxWidthPercent={maxWidthPercent}
              key={job.refId}
              parentDivWidth={parentDivWidth}
            >
              <FeaturedJobVerticalCard
                key={`vertical-${job.refId}`}
                cardDetails={job}
                userRefId={userRefId}
                isMobile={isMobile ?? false}
                isSelected={job.refId === currentlySelectedCard.refId}
                handleSelectJob={handleSelectJob}
                handleDeselect={handleDeselect}
              />
            </JobCardContainer>
          );
        })}
      </JobCardOuterContainer>
    </Container>
  );
}
