import { AutoResizeTextarea } from "components/AutoResizeTextArea";
import Text, { TextType } from "components/Text";
import { formatEmploymentType } from "core/jobs/service";
import responsiveStyling from "lib/responsive";
import {
  BackgroundColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import { ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${BackgroundColorPalette.BASIC_DECORATION_BLUE};
  padding: 40px 0px;
  margin-top: 40px;
  height: 100%;
  width: 100%;
`;

// TODO - make responsive
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
  margin-bottom: 32px;
`;

const FullWidthSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  ${responsiveStyling({
    "<768": `
    overflow: scroll;
    `,
  })};
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 32px;
  ${responsiveStyling({
    ">1200": `
    flex-direction: row;
    align-items: center;
    `,
  })};
`;

interface HeaderStyleProps {
  noPadding?: boolean;
}

const Header = styled.div<HeaderStyleProps>`
  ${({ noPadding }) =>
    !noPadding &&
    `
    padding-bottom: 8px;
`}
`;

interface Props {
  description?: string;
  requirements?: string;
  employmentType?: string;
  startDate?: string;
  compensation?: string;
  isMobile?: boolean;
}

export default function JobEmploymentInformation(props: Props): ReactElement {
  const { description, requirements, employmentType, compensation } = props;

  return (
    <Container>
      <JobSection
        header={"Description"}
        details={description}
        // maxRows={isMobile ? 20 : undefined}
        fullWidth
      />
      <JobSection
        header={"Qualifications"}
        details={requirements}
        // maxRows={isMobile ? 20 : undefined}
        fullWidth
      />
      <DetailsRow>
        <JobSection
          header={"Employment Type"}
          details={formatEmploymentType(employmentType)}
        />
        {/* <JobSection header={"Start Date"} details={startDate} /> */}
        <JobSection header={"Compensation"} details={compensation} />
      </DetailsRow>
    </Container>
  );
}

interface SectionProps {
  header: string;
  details?: string;
  fullWidth?: boolean;
  maxRows?: number;
}

function JobSection({
  header,
  details,
  fullWidth,
  maxRows,
}: SectionProps): ReactElement {
  if (fullWidth) {
    return (
      <FullWidthSectionContainer>
        <Header>
          <Text type={TextType.LABEL_2}>{header}</Text>
        </Header>
        <AutoResizeTextarea
          readOnly
          value={!details ? "Missing" : details}
          // @ts-ignore
          border="none"
          marginLeft="-15px"
          minRows={1}
          maxRows={maxRows ?? undefined}
        />
      </FullWidthSectionContainer>
    );
  }
  return (
    <SectionContainer>
      <Header>
        <Text type={TextType.LABEL_2}>{header}</Text>
      </Header>
      <Text type={TextType.PARAGRAPH} fontColor={TextAndShapesPalette.INPUT}>
        {details ?? "-"}
      </Text>
    </SectionContainer>
  );
}
