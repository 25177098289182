import React from "react";

const BASE_WIDTH = 24;
const BASE_HEIGHT = 25;

export default function LocationIcon(props: {
  height?: number;
  width?: number;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.938 11.5767C13.4124 11.5767 14.6077 10.3814 14.6077 8.90694C14.6077 7.43248 13.4124 6.23718 11.938 6.23718C10.4635 6.23718 9.26822 7.43248 9.26822 8.90694C9.26822 10.3814 10.4635 11.5767 11.938 11.5767Z"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.938 21.5884L11.09 22.1184C11.2728 22.4107 11.5932 22.5884 11.938 22.5884C12.2828 22.5884 12.6033 22.4107 12.786 22.1184L11.938 21.5884ZM11.938 21.5884C12.786 22.1184 12.7861 22.1183 12.7861 22.1182L12.7863 22.1179L12.7869 22.117L12.789 22.1136L12.7967 22.1012L12.8261 22.0538C12.8518 22.0124 12.8894 21.9515 12.9377 21.8727C13.0345 21.715 13.1744 21.4854 13.3483 21.1956C13.696 20.6161 14.1802 19.795 14.7275 18.8266C15.8191 16.8954 17.1745 14.3559 18.1967 11.9707C20.4981 6.60087 16.619 1.90002 11.938 1.90002C7.25712 1.90002 3.37803 6.60087 5.67937 11.9707C6.70163 14.3559 8.05699 16.8954 9.14858 18.8266C9.69592 19.795 10.1801 20.6161 10.5278 21.1956C10.7017 21.4854 10.8416 21.715 10.9383 21.8727C10.9867 21.9515 11.0243 22.0124 11.05 22.0538L11.0794 22.1012L11.0871 22.1136L11.0892 22.117L11.0898 22.1179L11.0899 22.1182C11.09 22.1183 11.09 22.1184 11.938 21.5884Z"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
