import Head from "next/head";
import { JobDetails } from "./types";

interface OwnProps {
  job: JobDetails;
}

export function getValidThroughDate(datePosted: string): string {
  const date = new Date(datePosted);
  date.setMonth(date.getMonth() + 2);
  return date.toISOString().slice(0, 10) + "T00:00";
}

export default function JobStructuredData(props: OwnProps): JSX.Element {
  const { job } = props;
  const formattedEmploymentType = job.employmentType
    ? job.employmentType?.replace(/ /g, "_").toUpperCase()
    : "OTHER";
  const validThrough: string | null = job.postedDate
    ? getValidThroughDate(job.postedDate)
    : null;

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    // "identifier": {
    //   "@type": "PropertyValue",
    //   "name": job.employer,
    //   "value": "1234567"
    // },
    datePosted: job.postedDate,
    ...(validThrough && { validThrough }),
    description: `<p>${job.description}</p>`,
    // "validThrough" : "2017-03-18T00:00",
    employmentType: formattedEmploymentType,
    hiringOrganization: {
      "@type": "Organization",
      logo: job.employerLogoUrl,
      name: job.employer,
      sameAs: job.redirectUrl,
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: job.country,
        addressLocality: job.city,
        addressRegion: job.state,
      },
    },
    title: job.title,
  };
  return (
    <Head>
      <title>{`10ure Jobs`}</title>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    </Head>
  );
}
